import { setHeaders, url } from "../../helper/api";

import axios from "axios";

export const signIn = (username, password) => {
  return (dispatch) => {
    axios
      .post(
        `${url}/login`,
        { username: username, password: password },
        setHeaders({
          "content-type": "application/json"
        })
      )
      .then((responce) => {
        if (responce.data.status) {
          sessionStorage.setItem("token", responce.data.token);

          dispatch({
            type: "SIGN_IN",
            token: responce.data.token,
          });
        } else {
          dispatch({
            type: "SIGN_IN_ERROR",
            error: responce.data.message,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        // toast.error(error.response?.data, {
        //   position: toast.POSITION.BOTTOM_RIGHT,
        // });
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "SIGN_OUT",
    });
  };
};

export const loadUser = () => {
  return (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      dispatch({
        type: "USER_LOADED",
        token,
      });
    } else return null;
  };
};
