import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { signIn } from "../../store/actions/authActions";

function Login(props) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  function onLogin(e) {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      isLoading: true,
    });
    dispatch(signIn(username, password));
    // window.location.reload();
  }

  if (auth.token) return <Redirect to="/" />;

  console.log(auth.error);

  return (
    <div className="page main-signin-wrapper" style={{ background: "#1e1635" }}>
      <div className="be-content">
        <div className="side-menu sticky"></div>
        <div className="row text-center pl-0 pr-0 ml-0 mr-0">
          <div className="col-lg-4 d-block mx-auto">
            <div
              className="text-center mb-2"
              style={{
                fontWeight: "500",
                fontSize: "24px",
                fontStyle: "revert",
                color:"#fff"
              }}
            >
              <img
                src="assets/img/brand/logo-light.png"
                className="header-brand-img"
                alt="logo"
                style={{marginRight:"5px"}}
              />
              <img
                src="assets/img/brand/logo-light.png"
                className="header-brand-img theme-logos"
                alt="logo"
                style={{marginRight:"5px"}}
              />
              CateCoin
            </div>
            <div className="card custom-card">
              <div className="card-body">
                <form onSubmit={onLogin}>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      placeholder="Username"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  {auth.error ? (
                    <div className="alert alert-outline-danger" role="alert">
                      <strong>Oh snap!</strong> {auth.error}
                    </div>
                  ) : (
                    ""
                  )}
                  {/* {auth.isLoading && (
                    <div className="bg-red-300 text-red border border-red-900 p-1 my-2">
                      Please wail...
                    </div>
                  )} */}
                  <div className="form-group login-submit">
                    <button
                      type="submit"
                      className="btn btn-primary btn-xl"
                      data-dismiss="modal"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
// const mapStateToProps = (state) => {
//   return {
//     errorMessage: state.auth.errorMessage,
//     successMessage: state.auth.successMessage,
//     showLoading: state.auth.showLoading,
//   };
// };

// export default connect(mapStateToProps)(Login);
