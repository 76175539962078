import jwtDecode from "jwt-decode";

const initialState = {
  token: sessionStorage.getItem("token"),
  username: null,
  id: null,
  error: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SIGN_IN":
    case "USER_LOADED":
      const user = jwtDecode(action.token);
      return {
        ...initialState,
        token: action.token,
        username: user.name,
        id: user.id,
      };
    case "SIGN_IN_ERROR":
      return {
        ...initialState,
        error: action.error,
      };
    case "SET_LOADING":
      return {
        ...initialState,
        isLoading: action.isLoading,
      };
    case "SIGN_OUT":
      console.log("SIGN_OUT");
      sessionStorage.removeItem("token");
      return {
        token: null,
        username: null,
        id: null,
      };
    default:
      return state;
  }
};

export default authReducer;
