import { Redirect, Route, Switch } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Login from "./components/block/login";
import Theme from "./components/block/theme";
import { loadUser } from "./store/actions/authActions";

const Home = lazy(() => import("./components/home/index"));
const Banner = lazy(() => import("./components/banner/index"));
const Token = lazy(() => import("./components/token/index"));
const Event = lazy(() => import("./components/event/index"));
const Account = lazy(() => import("./components/account/index"));
const Categories = lazy(() => import("./components/categories/index"));
const Dapp = lazy(() => import("./components/dapp/index"));
const Transactions = lazy(() => import("./components/transactions/index"));
const contact = lazy(() => import("./components/contact/index"));
const settings = lazy(() => import("./components/settings/index"));
const Apiusers = lazy(() => import("./components/api_users/index"));

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  let routes = (
    <Switch>
      <Route path="/" component={Login} />
      <Redirect to="/" />
    </Switch>
  );
  if (auth.token) {
    routes = (
      <Switch>
        <Theme exact path="/" component={Home} />
        <Theme exact path="/banner" component={Banner} />
        <Theme exact path="/token" component={Token} />
        <Theme exact path="/event" component={Event} />
        <Theme exact path="/account" component={Account} />
        <Theme exact path="/categories" component={Categories} />
        <Theme exact path="/dapp" component={Dapp} />
        <Theme exact path="/transactions" component={Transactions} />
        <Theme exact path="/contact" component={contact} />
        <Theme exact path="/settings" component={settings} />
        <Theme exact path="/api_users" component={Apiusers} />
        <Redirect to="/" />
      </Switch>
    );
  }
  return (
    <Suspense
      fallback={
        <div id="global-loader">
          <img
            src="assets/img/loader.svg"
            className="loader-img"
            alt="Loader"
          />
        </div>
      }
    >
      {routes}
    </Suspense>
  );
}

export default App;
