import React from "react";
import { Route } from "react-router-dom";
import Sidebar from "./sidebar";
import Header from "./header";

// const Theme = (PassedComponent) => {
//   return class extends React.Component {
//     render() {
//       return (
//         <div>
//           <Sidebar />
//           <div className="main-content side-content pt-0">
//             <Header />
//             <div className="container-fluid">
//               <PassedComponent {...this.props} />
//             </div>
//           </div>
//         </div>
//       );
//     }
//   };
// };
// class Theme extends React.Component {
//   render() {
//     let { component } = this.props;
//     console.log(this.props)
//     // let { history } = this.props;
//     return (
//       <div>
//         <Sidebar />
//         <div className="main-content side-content pt-0">
//           <Header />
//           <div className="container-fluid">{component}</div>
//         </div>
//       </div>
//     );
//   }
// }
// export default function Theme(component) {
//   return (
//     <div>
//       <Sidebar />
//       <div className="main-content side-content pt-0">
//         <Header />
//         <div className="container-fluid">{component}</div>
//       </div>
//     </div>
//   );
// }
export const Theme = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // authorised so return component
      return (
        <div>
          <Sidebar />
          <div className="main-content side-content pt-0">
            <Header />
            <div className="container-fluid">
              <Component {...props} />
            </div>
          </div>
        </div>
      );
    }}
  />
);
export default Theme;
