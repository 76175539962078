export const url = "https://catecoin.app/new_api/admin";
export const BASE = "https://catecoin.app/new_api";
// export const url = "http://localhost:4000/admin";
// export const BASE = "http://localhost:4000";

export const setHeaders = (header = null,responseType = null) => {
  if (!header) {
    header = {
      "content-type": "application/json",
      "x-auth-token": localStorage.getItem("token"),
    };
  }

  const headers = {
    headers: header,
  };

  if(responseType != null){
    headers.responseType = responseType;
  }

  return headers;
};
