import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signOut } from "../../store/actions/authActions";

export default function Sidebar(props) {
  // const history = useHistory();
  const dispatch = useDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
    window.location.reload();
  };

  return (
    <div className="main-sidebar main-sidebar-sticky side-menu">
      <div className="sidemenu-logo">
        <a className="main-logo" href="#/">
          <img
            src="assets/img/brand/logo-light.png"
            className="header-brand-img desktop-logo"
            alt="logo"
          />
          <img
            src="assets/img/brand/icon-light.png"
            className="header-brand-img icon-logo"
            alt="logo"
          />
          <img
            src="assets/img/brand/logo-light.png"
            className="header-brand-img desktop-logo theme-logo"
            alt="logo"
          />
          <img
            src="assets/img/brand/icon-light.png"
            className="header-brand-img icon-logo theme-logo"
            alt="logo"
          />
        </a>
      </div>
      <div className="main-sidebar-body">
        <ul className="nav">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              <i className="fe fe-airplay"></i>
              <span className="sidemenu-label">Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/account">
              <i className="fas fa-user"></i>
              <span className="sidemenu-label">Account</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/api_users">
              <i className="fas fa-user"></i>
              <span className="sidemenu-label">Api users</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/token">
              <i className="fas fa-coins"></i>
              <span className="sidemenu-label">Token</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/transactions">
              <i className="fas fa-exchange-alt"></i>
              <span className="sidemenu-label">Transactions</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/event">
              <i className="fas fa-calendar-alt"></i>
              <span className="sidemenu-label">Event</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/banner">
              <i className="fe fe-image"></i>
              <span className="sidemenu-label">Banner</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/categories">
              <i className="fa fa-list-alt"></i>
              <span className="sidemenu-label">Categories</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/dapp">
              <i className="fas fa-cubes"></i>
              <span className="sidemenu-label">Dapp</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/settings">
            <i className="fa fa-cog"></i>
              <span className="sidemenu-label">settings</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              <i className="fas fa-headset"></i>
              <span className="sidemenu-label">Help</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="" onClick={() => handleSignOut()}>
              <i className="fe fe-power"></i>
              <span className="sidemenu-label">signOut</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
