import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../store/actions/authActions";

export default function Header(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.auth);

  const handleSignOut = () => {
    dispatch(signOut());
    history.push("/");
  };

  return (
    <div className="main-header side-header sticky">
      <div className="container-fluid">
        <div className="main-header-left">
          <a className="main-logo d-lg-none" href="#/">
            <img
              src="assets/img/brand/logo.png"
              className="header-brand-img desktop-logo"
              alt="logo"
            />
            <img
              src="assets/img/brand/icon.png"
              className="header-brand-img icon-logo"
              alt="logo"
            />
            <img
              src="assets/img/brand/logo.png"
              className="header-brand-img desktop-logo theme-logo"
              alt="logo"
            />
            <img
              src="assets/img/brand/icon.png"
              className="header-brand-img icon-logo theme-logo"
              alt="logo"
            />
          </a>
          <a className="main-header-menu-icon" href="#/" id="mainSidebarToggle">
            <span></span>
          </a>
        </div>
        <div className="main-header-right">
          {state.username}
          <div className="dropdown main-profile-menu">
            <a className="main-img-user" href="#/">
              <img alt="avatar" src="assets//img/users/1.jpg" />
            </a>
            <div className="dropdown-menu">
              <div className="header-navheading">
                <h6 className="main-notification-title">{state.username}</h6>
              </div>
              <a
                className="dropdown-item"
                href="#/"
                onClick={() => handleSignOut()}
              >
                <i className="fe fe-power"></i> Sign Out
              </a>
            </div>
          </div>
          {/* <div className="dropdown d-md-flex header-settings">
            <a
              href="#/"
              className="nav-link icon"
              data-toggle="sidebar-right"
              data-target=".sidebar-right"
            >
              <i className="fe fe-align-right"></i>
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
